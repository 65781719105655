import { Agent } from '@/types/deals';
import { Feature, Measurement, Option, PartialRecord, typedObjectKeys } from '@/types/generics';
import { User } from '@/types/users';
import { GenericNote } from '@/types/Vehicle/notes';
import { z } from 'zod';
import { CombustibleType } from '../Deal';
import { VehicleFeatureItem } from './features';
import { DriveTrainType } from '@/types/Vehicle/options/driveTrainOptions';
import { TimingBeltType } from '@/types/Vehicle/options/timingBeltOptions';
import { SortingDirectionType } from '@/lib/shared/Sorting';

export enum VehiclesSortingField {
    EXTERNAL_ID = 'EXTERNAL_ID',
    CREATED_AT = 'CREATED_AT',
    PROCESSOR_AGENT_NAME = 'PROCESSOR_AGENT_NAME',
    MAKE_NAME = 'MAKE_NAME',
    MODEL_NAME = 'MODEL_NAME',
    VERSION_NAME = 'VERSION_NAME',
    YEAR_REGISTRATION = 'YEAR_REGISTRATION',
    MILEAGE = 'MILEAGE',
    STATUS = 'STATUS',
    SUBSTATUS = 'SUBSTATUS',
    AD_PRICE_AMOUNT = 'AD_PRICE_AMOUNT',
}

const vehicleSortTypeEnum: readonly string[] = Object.keys(VehiclesSortingField);
const VehicleSortingType = z.enum([vehicleSortTypeEnum[0], ...vehicleSortTypeEnum]);

const RepricingTypeEnum = z.ZodEnum.create(['TARGET', 'AGREED', 'SENT']);
const SearchModeEnum = z.ZodEnum.create(['AND', 'OR']);

export const VEHICLE_FILTER_TYPES = {
    CONTACTABLE: 'contactable',
    ALL: 'all',
};

export type VEHICLE_FILTER_TYPES = (typeof VEHICLE_FILTER_TYPES)[keyof typeof VEHICLE_FILTER_TYPES];

export const gearBoxTypes = {
    MANUAL: 'Manual',
    AUTOMATIC: 'Automático',
};
export const energyClassificationTypes = {
    ZERO: '0',
    ECO: 'Eco',
    C: 'C',
    B: 'B',
    NO_BADGE: 'Sin etiqueta',
};

export const vehicleTypes: Record<string, string> = {
    CAR: 'Coche',
    INDUSTRIAL_VAN: 'Furgoneta industrial',
};

export const productSources = {
    COCHESNET: 'Coches.net',
    AUTOCASION: 'Autocasion',
    WALLAPOP: 'Wallapop',
    CLIDRIVECOM: 'Clidrive.com',
    // COCHESCOM: 'Coches.com',
};

export const adSources = {
    COCHESNET: 'Coches.net',
    AUTOCASION: 'Autocasion',
    WALLAPOP: 'Wallapop',
    DEALER: 'Concesionario',
    CLIDRIVECOM: 'Clidrive.com',
    // COCHESCOM: 'Coches.com',
};

export const sourcesImagesLinks = {
    COCHESNET: '/images/logos/cochesnet.png',
    AUTOCASION: '/images/logos/autocasion.webp',
    MILANUNCIOS: '/images/logos/milanuncios.webp',
    WALLAPOP: '/images/logos/wallapop.webp',
    DEALER: '/images/logos/dealer.webp',
    CLIDRIVECOM: '/images/logos/clidrivecom.webp',
    // COCHESCOM: '/images/logos/cochescom.jpeg',
};

export const repricingTypes = {
    TARGET: 'Precios objetivo',
    AGREED: 'Precios conseguidos',
    SENT: 'Repricing enviado',
};

export const repricingReasons = {
    REPRICING_ACCEPTED: 'Repricing aceptado ',
    LOWERED_BY_CERTIFICATION: 'Bajada por certificación',
    LOWERED_BY_NEGOTIATION: 'Bajada por negociación',
};

export const marketPriceType = {
    SUPER_PRICE: 'Super precio',
    GOOD_PRICE: 'Buen precio',
    FAIR_PRICE: 'Precio justo',
    HIGH_PRICE: 'Precio elevado',
    VERY_HIGH_PRICE: 'Precio alto',
    PRICE_NOT_PROVIDED: 'Sin etiqueta',
};

const [firstGearBoxTypeKey, ...restGearBoxTypeKeys] = typedObjectKeys(gearBoxTypes);
const [firstEnergyClassificationTypeKey, ...restEnergyClassificationTypeKeys] =
    typedObjectKeys(energyClassificationTypes);
const [firstMarketPriceTypeKey, ...restMarketPriceTypeKey] = typedObjectKeys(marketPriceType);
const [firstSourcesImagesLinksKey, ...restSourcesImagesLinksKeys] = typedObjectKeys(sourcesImagesLinks);
export const GearboxType = z.enum([firstGearBoxTypeKey, ...restGearBoxTypeKeys]);
export const EnergyClassificationType = z.enum([firstEnergyClassificationTypeKey, ...restEnergyClassificationTypeKeys]);
export const ProductSources = z.enum([firstSourcesImagesLinksKey, ...restSourcesImagesLinksKeys]);
export const MarketPriceType = z.enum([firstMarketPriceTypeKey, ...restMarketPriceTypeKey]);
export const vehicleTypeEnum: readonly string[] = Object.keys(vehicleTypes);
export const VehicleType = z.enum([vehicleTypeEnum[0], ...vehicleTypeEnum]);

export const GetVehiclePriceResponse = z.object({
    loanTotalCost: Measurement.optional(),
    totalCashCost: Measurement.optional(),
    monthlyFee: Measurement.optional(),
    askedAmount: Measurement.optional(),
    adminFee: Measurement.optional(),
    premiumWarranty: z.string().nullable().optional(),
});
export const GetVehicleBasicInfoResponse = z.object({
    uuid: z.string().uuid().nullable().optional(),
    description: z.string().nullable().optional(),
    licensePlate: z.string().nullable().optional(),
    make: VehicleFeatureItem.nullable().optional(),
    model: VehicleFeatureItem.nullable().optional(),
    version: VehicleFeatureItem.nullable().optional(),
    year: VehicleFeatureItem.nullable().optional(),
    color: VehicleFeatureItem.nullable().optional(),
    province: VehicleFeatureItem.nullable().optional(),
    gearboxType: GearboxType.nullable().optional(),
    mileage: z.number().nullable().optional(),
    power: z.number().nullable().optional(),
    combustibleType: CombustibleType.nullable().optional(),
    drivetrainType: DriveTrainType.nullable().optional(),
    timingBeltType: TimingBeltType.nullable().optional(),
    yearRegistration: z.string().datetime().optional(),
    numberOfDoors: z.number().nullable().optional(),
    numberOfSeats: z.number().nullable().optional(),
    transferable: z.string().nullable().optional(),
    energyClassificationType: EnergyClassificationType.nullable().optional(),
    createdAt: z.string().datetime().optional(),
});

export const GetVehicleExtrasResponse = z.object({
    extras: z.array(VehicleFeatureItem),
});

export const GetVehicleExtrasInfoResponse = z.object({
    extras: z.array(VehicleFeatureItem).default([]),
});

export const GetVehiclePerformanceResponse = z
    .object({
        fuelTankCapacity: Measurement.nullable().optional(),
        fuelConsumption: Measurement.nullable().optional(),
        electricConsumption: Measurement.nullable().optional(),
        batteryCapacity: Measurement.nullable().optional(),
        electricRangeWLTP: Measurement.nullable().optional(),
        chargingTime: Measurement.nullable().optional(),
    })
    .nullable();

export const GetVehicleStatusResponse = z
    .object({
        isApproved: z.boolean().nullable().optional(),
        certifierAgent: Agent.nullable().optional(),
    })
    .nullable();

export const VehiclePricing = GetVehiclePriceResponse.extend({
    loanTotalCost: z.string().nullable().optional(),
    totalCashCost: z.string().nullable().optional(),
    monthlyFee: z.string().nullable().optional(),
    askedAmount: z.string().nullable().optional(),
    adminFee: z.string().nullable().optional(),
});

export const VehicleBasicInfo = GetVehicleBasicInfoResponse.extend({
    yearRegistration: z.date().nullable().optional(),
    make: Option.nullable().optional(),
    model: Option.nullable().optional(),
    version: Option.nullable().optional(),
    year: Option.nullable().optional(),
    color: Option.nullable().optional(),
    province: Option.nullable().optional(),
});

export const VehicleExtras = z.object({});

export const VehiclePerformance = z.object({
    fuelTankCapacity: z.string().nullable().optional(),
    fuelConsumption: z.string().nullable().optional(),
    electricConsumption: z.string().nullable().optional(),
    batteryCapacity: z.string().nullable().optional(),
    electricRangeWLTP: z.string().nullable().optional(),
    chargingTime: z.string().nullable().optional(),
});
export const VehicleStatus = z.object({
    isApproved: z.string().nullable().optional(),
    certifierAgent: Agent.nullable().optional(),
});

export const PostCatalogueVehicleForm = z.object({
    licensePlate: z.string().nullable(),
    adPrice: z.number().nullable(),
    mileage: z.number().nullable(),
    yearRegistration: z.number().nullable(),
    combustibleType: CombustibleType.nullable(),
    provinceUuid: Option.nullable(),
    makeUuid: Option.nullable(),
    modelUuid: Option.nullable(),
    versionUuid: Option.nullable(),
    sourceName: ProductSources,
    link: z.string().nullable(),
    publishedAt: z.string().datetime().nullable(),
    description: z.string().nullable(),
    sellerEmail: z.string().nullable(),
    sellerPhone: z.string().nullable(),
    sellerName: z.string().nullable(),
    sellerFirstSurname: z.string().nullable(),
    sellerSecondSurname: z.string().nullable(),
});

export const PostCatalogueVehicle = z.object({
    licensePlate: z.string().nullable(),
    adPrice: Measurement.omit({ unit: true }).nullable(),
    mileage: z.number().nullable(),
    yearRegistration: z.number().nullable(),
    combustibleType: CombustibleType.nullable(),
    provinceUuid: z.string().nullable(),
    makeUuid: z.string().nullable(),
    modelUuid: z.string().nullable(),
    versionUuid: z.string().nullable(),
    sourceName: ProductSources,
    link: z.string().nullable(),
    publishedAt: z.string().datetime().nullable(),
    description: z.string().nullable(),
    sellerEmail: z.string().nullable(),
    sellerPhone: z.string().nullable(),
    sellerName: z.string().nullable(),
    sellerFirstSurname: z.string().nullable(),
    sellerSecondSurname: z.string().nullable(),
});

export const VehicleInfoView = z.object({
    pricing: VehiclePricing.optional(),
    basicInfo: VehicleBasicInfo.optional(),
    extras: VehicleExtras.optional(),
    performance: VehiclePerformance.optional(),
    status: VehicleStatus.optional(),
});

export const catalogueVehicleMainStatuses = {
    NEW: 'Nuevo',
    NOT_ELIGIBLE: 'No Elegible',
    CONTACTED: 'Contactado',
    APPROVED: 'Aprobado',
    PUBLISHED: 'Publicado',
    UNPUBLISHED: 'Despublicado',
    DISCARDED: 'Descartado',
};

export const catalogueVehicleSubStatuses = {
    PENDING_APPRAISAL: 'Pendiente de tasación',
    PENDING_CONTACTED_APPRAISAL: 'Pendiente de tasación',
    NO_ADVERTISEMENT: 'Anuncio no disponible',
    NO_PHONE: 'Sin teléfono',
    NO_MINIMUM_INFORMATION: 'Sin información mínima',
    DUPLICATE: 'Duplicado',
    OUTSIDE_CRITERIA: 'Fuera de criterio',
    OUT_OF_PRICE: 'Fuera de precio',
    LOW_PRICE: 'Precio bajo',
    PENDING_CONTACT: 'Pendiente de contacto',
    CONTACTED_BY_PHONE: 'Contactado por teléfono',
    CONTACTED_BY_WHATSAPP: 'Contactado por WhatsApp',
    IN_PROCESS: 'En gestión',
    SENT_TO_BOT: 'Enviado a bot',
    PENDING_DOCUMENTATION: 'Pendiente de documentación',
    DOCUMENTATION_RECEIVED: 'Documentación recibida',
    PENDING_PHOTO_EDITING: 'Pendiente de edición fotos',
    PENDING_EXTRAS: 'Pendiente de añadir extras',
    PENDING_PUBLICATION: 'Pendiente de publicación',
    PUBLISHED_STOCK: 'Publicado en stock',
    PROPOSED_REPRICING: 'Repricing propuesto',
    REPRICING_ACCEPTED: 'Repricing aceptado',
    REPRICING_DENIED: 'Repricing denegado',
    REPRICING_SENT: 'Repricing enviado',
    SOLD: 'Vendido',
    SOLD_BY_OWNER: 'Vendido por el dueño',
    OWNER_NOT_SELLING: 'Propietario no quiere vender',
    PRICE_DISAGREEMENT: 'Desacuerdo de precio',
    NOT_INTERESTED: 'No interesado',
    NOT_AVAILABLE: 'No disponible',
    NO_RESPONSE: 'Sin respuesta',
    VEHICLE_NOT_ELIGIBLE: 'Vehículo no apto',
    PHOTO_EDITING_KO: 'Edición de fotos KO',
};

export const catalogueVehiclesSubStatusesMap: Record<
    CatalogueVehicleMainStatuses,
    PartialRecord<CatalogueVehicleSubStatuses, string>
> = {
    NEW: {
        PENDING_APPRAISAL: catalogueVehicleSubStatuses.PENDING_APPRAISAL,
    },
    NOT_ELIGIBLE: {
        NO_ADVERTISEMENT: catalogueVehicleSubStatuses.NO_ADVERTISEMENT,
        NO_PHONE: catalogueVehicleSubStatuses.NO_PHONE,
        NO_MINIMUM_INFORMATION: catalogueVehicleSubStatuses.NO_MINIMUM_INFORMATION,
        DUPLICATE: catalogueVehicleSubStatuses.DUPLICATE,
        OUTSIDE_CRITERIA: catalogueVehicleSubStatuses.OUTSIDE_CRITERIA,
        OUT_OF_PRICE: catalogueVehicleSubStatuses.OUT_OF_PRICE,
        LOW_PRICE: catalogueVehicleSubStatuses.LOW_PRICE,
    },
    CONTACTED: {
        PENDING_CONTACT: catalogueVehicleSubStatuses.PENDING_CONTACT,
        PENDING_CONTACTED_APPRAISAL: catalogueVehicleSubStatuses.PENDING_CONTACTED_APPRAISAL,
        CONTACTED_BY_PHONE: catalogueVehicleSubStatuses.CONTACTED_BY_PHONE,
        CONTACTED_BY_WHATSAPP: catalogueVehicleSubStatuses.CONTACTED_BY_WHATSAPP,
        IN_PROCESS: catalogueVehicleSubStatuses.IN_PROCESS,
        SENT_TO_BOT: catalogueVehicleSubStatuses.SENT_TO_BOT,
    },
    APPROVED: {
        PENDING_DOCUMENTATION: catalogueVehicleSubStatuses.PENDING_DOCUMENTATION,
        DOCUMENTATION_RECEIVED: catalogueVehicleSubStatuses.DOCUMENTATION_RECEIVED,
        PENDING_PHOTO_EDITING: catalogueVehicleSubStatuses.PENDING_PHOTO_EDITING,
        PHOTO_EDITING_KO: catalogueVehicleSubStatuses.PHOTO_EDITING_KO,
        PENDING_EXTRAS: catalogueVehicleSubStatuses.PENDING_EXTRAS,
        PENDING_PUBLICATION: catalogueVehicleSubStatuses.PENDING_PUBLICATION,
    },
    PUBLISHED: {
        PUBLISHED_STOCK: catalogueVehicleSubStatuses.PUBLISHED_STOCK,
        PROPOSED_REPRICING: catalogueVehicleSubStatuses.PROPOSED_REPRICING,
        REPRICING_ACCEPTED: catalogueVehicleSubStatuses.REPRICING_ACCEPTED,
        REPRICING_DENIED: catalogueVehicleSubStatuses.REPRICING_DENIED,
        REPRICING_SENT: catalogueVehicleSubStatuses.REPRICING_SENT,
    },
    UNPUBLISHED: {
        SOLD: catalogueVehicleSubStatuses.SOLD,
        SOLD_BY_OWNER: catalogueVehicleSubStatuses.SOLD_BY_OWNER,
        OWNER_NOT_SELLING: catalogueVehicleSubStatuses.OWNER_NOT_SELLING,
        PRICE_DISAGREEMENT: catalogueVehicleSubStatuses.PRICE_DISAGREEMENT,
    },
    DISCARDED: {
        PRICE_DISAGREEMENT: catalogueVehicleSubStatuses.PRICE_DISAGREEMENT,
        NO_ADVERTISEMENT: catalogueVehicleSubStatuses.NO_ADVERTISEMENT,
        NOT_INTERESTED: catalogueVehicleSubStatuses.NOT_INTERESTED,
        NOT_AVAILABLE: catalogueVehicleSubStatuses.NOT_AVAILABLE,
        NO_RESPONSE: catalogueVehicleSubStatuses.NO_RESPONSE,
        VEHICLE_NOT_ELIGIBLE: catalogueVehicleSubStatuses.VEHICLE_NOT_ELIGIBLE,
    },
};

export const vatRates = {
    REBU: 'REBU',
    IVA: 'IVA',
};

const [firstMainStatusStatusesKey, ...restMainStatusStatusesKeys] = typedObjectKeys(catalogueVehicleMainStatuses);
const [firstSubStatusStatusesKey, ...restsubStatusStatusesKeys] = typedObjectKeys(catalogueVehicleSubStatuses);
const [firstVatRateKey, ...restVatRateKeys] = typedObjectKeys(vatRates);

export const CatalogueVehicleMainStatuses = z.enum([firstMainStatusStatusesKey, ...restMainStatusStatusesKeys]);
export const CatalogueVehicleSubStatuses = z.enum([firstSubStatusStatusesKey, ...restsubStatusStatusesKeys]);
export const VatRate = z.enum([firstVatRateKey, ...restVatRateKeys]);

/* - CATALOGUE VEHICLE LIST VIEW - */

export const vehicleFiltersTypeButtonFilters: Record<string, Partial<CatalogueVehicleFormQueryFilters>> = {
    [VEHICLE_FILTER_TYPES.CONTACTABLE]: {
        statuses: 'NEW',
        subStatuses: 'PENDING_APPRAISAL',
        hasPhoneNumber: true,
        hasMarketValuation: true,
    },
    [VEHICLE_FILTER_TYPES.ALL]: {},
};

export const CatalogueVehicleFormQueryFilters = z.object({
    catalogueVehiclesId: z.number().optional(),
    extId: z.string().nullable().optional(),
    statuses: CatalogueVehicleMainStatuses.optional(),
    subStatuses: CatalogueVehicleSubStatuses.optional(),
    createdBetween: z.array(z.date()).optional(),
    createdAtTo: z.string().datetime().optional(),
    createdAtFrom: z.string().datetime().optional(),
    licensePlate: z.string().optional(),
    makeUuid: Option.nullable().optional(),
    modelUuid: Option.nullable().optional(),
    versionUuid: z.string().optional(),
    combustibleType: z.array(Option).nullable().optional(),
    yearRegistrationBetween: z.array(z.number()).optional(),
    yearRegistrationTo: z.number().optional(),
    yearRegistrationFrom: z.number().optional(),
    provinceUuids: z.array(Option).nullable().optional(),
    mileageBetween: z.array(z.number()).optional(),
    mileageTo: z.number().optional(),
    mileageFrom: z.number().optional(),
    processorAgentUuid: z.string().optional().nullable(),
    lastStatusTransitionBetween: z.array(z.date()).optional(),
    lastStatusTransitionDateFrom: z.string().datetime().optional(),
    lastStatusTransitionDateTo: z.string().datetime().optional(),
    lastNoteDateBetween: z.array(z.date()).optional(),
    lastNoteDateFrom: z.string().datetime().optional(),
    lastNoteDateTo: z.string().datetime().optional(),
    hasPhoneNumber: z.boolean().optional(),
    hasMarketValuation: z.boolean().optional(),
    clientUuid: z.string().optional(),
    clientPhone: z.string().optional(),
    vatRate: VatRate.optional(),
    'notifications[availability]': z.boolean().optional(),
    'notifications[repricing]': z.boolean().optional(),
    'notifications[searchMode]': SearchModeEnum.optional(),
    gearBoxTypes: GearboxType.optional(),
    powerBetween: z.array(z.number()).optional(),
    powerTo: z.number().optional(),
    powerFrom: z.number().optional(),
    colorUuid: Option.optional().nullable(),
});

export const CatalogueVehicleFormFilters = CatalogueVehicleFormQueryFilters.extend({
    processorAgent: z
        .object({
            label: z.string(),
            value: z.string(),
        })
        .nullable()
        .optional(),
});

export const CatalogueVehicleFilters = CatalogueVehicleFormQueryFilters.extend({
    max: z.number().optional(),
    offset: z.number().optional(),
    sortDirection: SortingDirectionType.optional(),
    sortField: VehicleSortingType.optional(),
});

export const CatalogueVehicleListDetails = z.object({
    id: z.number(),
    extId: z.string().nullable(),
    uuid: z.string(),
    licensePlate: z.string().nullable(),
    mileage: z.number().nullable(),
    yearRegistration: z.number().nullable(),
    adPrice: Measurement.nullable(),
    makeName: z.string().nullable(),
    modelName: z.string().nullable(),
    versionName: z.string().nullable(),
    status: CatalogueVehicleMainStatuses,
    subStatus: CatalogueVehicleSubStatuses.nullable(),
    processorAgent: z
        .object({
            uuid: z.string().uuid().optional(),
            name: z.string(),
        })
        .nullable(),
    createdAt: z.string(),
    lastStatusTransitionDate: z.string().nullable(),
    lastNoteDate: z.string().nullable(),
    lastNote: GenericNote.nullable(),
    client: z
        .object({
            uuid: z.string().uuid().optional(),
            name: z.string().optional(),
            phone: z.string().nullable(),
        })
        .nullable(),
    notificationsConfig: z.object({
        isRepricingPaused: z.boolean(),
        isAvailabilityPaused: z.boolean(),
    }),
    isMarketplaceOffer: z.boolean(),
});

export const GetCatalogueVehiclesListResponse = z.object({
    max: z.number(),
    offset: z.number(),
    items: z.array(CatalogueVehicleListDetails).default([]),
    totalItems: z.number(),
});

/* – CATALOGUE VEHICLE DETAILS – */

export const SellerAd = z.object({
    uuid: z.string(),
    source: ProductSources,
    link: z.string().nullable(),
    description: z.string().nullable(),
    sellerName: z.string().nullable(),
    sellerFirstSurname: z.string().nullable(),
    sellerSecondSurname: z.string().nullable(),
    sellerPhone: z.string().nullable(),
    sellerSecondPhone: z.string().nullable(),
    sellerEmail: z.string().nullable(),
    publishedAt: z.string().datetime().nullable(),
    createdAt: z.string().datetime(),
});

export const GetCatalogueVehicleDetailsManagedAd = z.object({
    uuid: z.string(),
    source: ProductSources,
    link: z.string(),
    publishedAt: z.string().datetime().nullable(),
    createdAt: z.string().datetime(),
});

export type GetCatalogueVehicleDetailsManagedAd = z.infer<typeof GetCatalogueVehicleDetailsManagedAd>;

export const GetCatalogueVehicleDetails = z.object({
    id: z.number(),
    uuid: z.string(),
    extId: z.string().nullable(),
    licensePlate: z.string().nullable(),
    vin: z.string().nullable(),
    description: z.string().nullable(),
    mileage: z.number().nullable(),
    power: z.number().nullable(),
    numberOfDoors: z.number().nullable(),
    numberOfSeats: z.number().nullable(),
    yearRegistration: z.number().nullable(),
    combustibleType: CombustibleType.nullable(),
    gearboxType: GearboxType.nullable(),
    drivetrainType: DriveTrainType.nullable(),
    timingBeltType: TimingBeltType.nullable(),
    energyClassificationType: EnergyClassificationType.nullable(),
    make: Feature.pick({ uuid: true, name: true }).nullable(),
    model: Feature.pick({ uuid: true, name: true }).nullable(),
    version: Feature.pick({ uuid: true, name: true }).nullable(),
    year: Feature.pick({ uuid: true, name: true }).nullable(),
    color: Feature.omit({ code: true, value: true }).nullable(),
    province: Feature.omit({ value: true }).nullable(),
    trasferable: z.boolean().nullable(),
    brandWarrantyExpiredAt: z.string().datetime().nullable(),
    status: CatalogueVehicleMainStatuses,
    subStatus: CatalogueVehicleSubStatuses,
    adPrice: Measurement.omit({ unit: true }).nullable(),
    targetPrices: z.object({
        purchasePrice: Measurement.omit({ unit: true }).nullable(),
        sellingPrice: Measurement.omit({ unit: true }).nullable(),
        sellingCountedPrice: Measurement.omit({ unit: true }).nullable(),
    }),
    agreedPrices: z.object({
        purchasePrice: Measurement.omit({ unit: true }).nullable(),
        sellingPrice: Measurement.omit({ unit: true }).nullable(),
        sellingCountedPrice: Measurement.omit({ unit: true }).nullable(),
    }),
    managedAds: z.array(GetCatalogueVehicleDetailsManagedAd).nullable(),
    sellerAd: SellerAd.nullable(),
    registrationDate: z.string().datetime().nullable(),
    vatRate: VatRate.nullable(),
    hasSecondKey: z.boolean().nullable(),
    hasMaintenanceLog: z.boolean().nullable(),
    processorAgent: Agent.nullable(),
    extraInfo: z.string().nullable(),
    marketValuation: z
        .object({
            adPrice: Measurement.nullable(),
            marketPrice: Measurement.nullable(),
            marketPriceType: MarketPriceType.nullable(),
            source: ProductSources,
            createdAt: z.string().datetime(),
        })
        .nullable(),
    createdAt: z.string().datetime(),
    vehicleType: VehicleType.nullable(),
    notificationsConfig: z.object({
        isRepricingPaused: z.boolean(),
        isAvailabilityPaused: z.boolean(),
    }),
    riskAlertImport: z.boolean().nullable(),
    sellingPriceToMarketType: MarketPriceType.nullable(),
    engineDisplacement: z.number().nullable(),
    lastVehicleInspectionDate: z.string().datetime().nullable(),
    nextVehicleInspectionDate: z.string().datetime().nullable(),
    ganvamPrice: Measurement.omit({ unit: true }).nullable(),
    ganvamPriceToMarketDeviation: z.number().nullable(),
});

export const PutCatalogueVehicleDetails = z.object({
    licensePlate: z.string().nullable().optional(),
    description: z.string().nullable().optional(),
    trasferable: z.boolean().nullable().optional(),
    mileage: z.number().nullable().optional(),
    power: z.number().nullable().optional(),
    numberOfDoors: z.number().nullable().optional(),
    numberOfSeats: z.number().nullable().optional(),
    yearRegistration: z.number().nullable().optional(),
    combustibleType: CombustibleType.nullable().optional(),
    gearboxType: GearboxType.nullable().optional(),
    drivetrainType: DriveTrainType.nullable().optional(),
    timingBeltType: TimingBeltType.nullable().optional(),
    energyClassificationType: EnergyClassificationType.nullable().optional(),
    provinceUuid: z.string().nullable().optional(),
    makeUuid: z.string().nullable().optional(),
    modelUuid: z.string().nullable().optional(),
    versionUuid: z.string().nullable().optional(),
    yearUuid: z.string().nullable().optional(),
    colorUuid: z.string().nullable().optional(),
    vatRate: VatRate.nullable().optional(),
    hasSecondKey: z.boolean().nullable().optional(),
    hasMaintenanceLog: z.boolean().nullable().optional(),
    registrationDate: z.date().nullable().optional(),
    vin: z.string().nullable().optional(),
    adPrice: Measurement.omit({ unit: true }).nullable().optional(),
    extraInfo: z.string().nullable().optional(),
    vehicleType: VehicleType.nullable().optional(),
    isRepricingPaused: z.boolean().optional(),
    isAvailabilityPaused: z.boolean().optional(),
    riskAlertImport: z.boolean().nullable().optional(),
    engineDisplacement: z.number().nullable().optional(),
    lastVehicleInspectionDate: z.date().nullable().optional(),
    nextVehicleInspectionDate: z.date().nullable().optional(),
    isMarketplaceOffer: z.boolean().nullable().optional(),
});

export const PutCatalogueVehicleDetailsForm = z.object({
    provinceUuid: Option.nullable(),
    makeUuid: Option.nullable(),
    modelUuid: Option.nullable(),
    versionUuid: Option.nullable(),
    trasferable: z.boolean().nullable(),
    hasSecondKey: z.boolean().nullable(),
    hasMaintenanceLog: z.boolean().nullable(),
    registrationDate: z.date().nullable(),
    color: Option,
    licensePlate: z.string().nullable(),
    description: z.string().nullable(),
    mileage: z.number().nullable(),
    power: z.number().nullable(),
    numberOfDoors: z.number().nullable(),
    numberOfSeats: z.number().nullable(),
    yearRegistration: z.number().nullable(),
    combustibleType: CombustibleType.nullable(),
    gearboxType: GearboxType.nullable(),
    drivetrainType: DriveTrainType.nullable(),
    timingBeltType: TimingBeltType.nullable(),
    energyClassificationType: EnergyClassificationType.nullable(),
    yearUuid: z.string().nullable(),
    colorUuid: z.string().nullable(),
    vatRate: VatRate.nullable(),
    vin: z.string().nullable(),
    adPrice: Measurement.omit({ unit: true }).nullable(),
    extraInfo: z.string().nullable(),
    vehicleType: VehicleType.nullable(),
    isRepricingPaused: z.boolean(),
    isAvailabilityPaused: z.boolean(),
    riskAlertImport: z.boolean().nullable(),
    engineDisplacement: z.number().nullable(),
    lastVehicleInspectionDate: z.date().nullable(),
    nextVehicleInspectionDate: z.date().nullable(),
});

/* – CATALOGUE VEHICLE STATUS CHANGE – */

export const GetNextCatalogueVehicleStatuses = z.object({
    nextStatuses: z.array(
        z.object({
            name: z.enum([firstMainStatusStatusesKey, ...restMainStatusStatusesKeys]),
            subStatuses: z.array(z.string()),
        }),
    ),
});

export const ChangeCatalogueVehicleStatusProps = z.object({
    status: CatalogueVehicleMainStatuses,
    subStatus: CatalogueVehicleSubStatuses.nullable(),
    reason: z.string().nullable().optional(),
});

/* – CATALOGUE VEHICLES TRANSITION HISTORY – */

export const CatalogueVehicleTransitionHistoryRecord = z.object({
    uuid: z.string(),
    currentStatus: CatalogueVehicleMainStatuses,
    previousStatus: CatalogueVehicleMainStatuses.nullable(),
    currentSubStatus: CatalogueVehicleSubStatuses.nullable(),
    previousSubStatus: CatalogueVehicleSubStatuses.nullable(),
    author: z.nullable(User),
    createdAt: z.string(),
    reason: z.string().nullable().optional(),
});

export const CatalogueVehicleTransitionHistory = z.array(CatalogueVehicleTransitionHistoryRecord);

/* - CATALOGUE VEHICLES MARKETING -*/

export const PostCatalogueVehicleManagedAd = z.object({
    source: ProductSources,
    link: z.string(),
    publishedAt: z.string().datetime().nullable(),
});

export const CatalogueVehicleManagedAd = z.object({
    uuid: z.string(),
    source: ProductSources,
    link: z.string(),
    publishedAt: z.string().datetime().nullable(),
    createdAt: z.string().datetime(),
});

/* - CATALOGUE VEHICLE REPRICING - */

const NullablePrice = Measurement.omit({ unit: true }).nullable();

export const PostCatalogueVehicleRepricing = z.object({
    purchasePrice: NullablePrice,
    sellingPrice: NullablePrice,
    sellingCountedPrice: NullablePrice,
    type: RepricingTypeEnum,
    reason: z.string().nullable(),
});

export const CatalogueVehicleRepricing = z.object({
    purchasePrice: NullablePrice,
    sellingPrice: NullablePrice,
    sellingCountedPrice: NullablePrice,
    type: RepricingTypeEnum,
    reason: z.string().nullable(),
    createdAt: z.string(),
    author: User.nullable(),
});

export const CatalogueVehicleRepricingHistoryItem = z.object({
    previousPurchasePrice: NullablePrice,
    previousSellingPrice: NullablePrice,
    previousSellingCountedPrice: NullablePrice,
    purchasePrice: NullablePrice,
    sellingPrice: NullablePrice,
    sellingCountedPrice: NullablePrice,
    type: RepricingTypeEnum,
    author: User.nullable(),
    createdAt: z.string(),
    reason: z.string().nullable(),
});

export const CatalogueVehicleRepricingHistory = z.array(CatalogueVehicleRepricingHistoryItem);

export const GetCatalogueVehicleRepricing = z.array(CatalogueVehicleRepricing);

/* - Bulk VEHICLE CREATION -*/

export enum BulkVehicleCreationErrors {
    not_found = 'not_found',
    required_field = 'required_field',
    not_allowed = 'not_allowed',
    invalid_number = 'invalid_number',
    invalid_price = 'invalid_price',
    invalid_phone = 'invalid_phone',
    invalid_province = 'invalid_province',
    invalid_label = 'invalid_label',
    invalid_year = 'invalid_year',
    invalid_email = 'invalid_email',
    vehicle_not_allowed = 'vehicle_not_allowed',
    database_duplicated = 'database_duplicated',
    csv_duplicated = 'csv_duplicated',
}

export const BulkVehicleCreationErrorsMap = {
    not_found: 'No encontrado',
    required_field: 'Es un campo requerido',
    not_allowed: 'Valor no permitido',
    invalid_number: 'Número introducido no válido',
    invalid_price: 'Precio introducido no válido',
    invalid_phone: 'Número de teléfono introducido no válido',
    invalid_province: 'Provincia introducida no válido',
    invalid_label: 'Etiqueta introducida no válido',
    invalid_year: 'Año introducido no válido',
    invalid_email: 'Email introducido no válido',
    vehicle_not_allowed: 'Tasación masiva no permitida para el vehículo',
    database_duplicated: 'Duplicado en base de datos',
    csv_duplicated: 'Duplicado en el excel',
};

export const BulkUpdateCsvSuccessesResponse = z.object({
    enqueuedOperations: z.number(),
});

export const BulkUpdateCsvUpdatesResponse = z.object({
    updates: z.number(),
    updatedVehicles: z.number(),
});

/* - Bulk REPRICING - */
export enum BulkVehicleUpdateCodes {
    REQUIRED_FIELD = 'required_field',
    NOT_ALLOWED = 'not_allowed',
    INVALID_NUMBER = 'invalid_number',
    INVALID_PRICE = 'invalid_price',
    NOT_FOUND = 'not_found',
    INVALID_STATUS = 'invalid_status',
    INVALID_SUB_STATUS = 'invalid_sub_status',
    INVALID_EMAIL = 'invalid_email',
    VEHICLE_NOT_ALLOWED = 'vehicle_not_allowed',
    VEHICLE_RESERVED = 'vehicle_reserved',
    HAS_RESERVATION = 'has_reservation',
}

export const BulkVehicleUpdateCodesMap = {
    required_field: 'Es un campo requerido',
    not_allowed: 'Valor no permitido',
    invalid_number: 'Número introducido no válido',
    invalid_price: 'Precio introducido no válido',
    not_found: 'No encontrado',
    invalid_status: 'Estado introducido no válido',
    invalid_sub_status: 'Subestado introducido no válido',
    invalid_email: 'Email introducido no válido',
    vehicle_not_allowed: 'Actualización no permitida para el vehículo',
    vehicle_reserved: 'No se hizo repricing porque el vehículo está reservado (nota añadida)',
    has_reservation: 'No se hizo repricing porque el vehículo está reservado',
};

// TYPES

export type RepricingTypeEnum = z.infer<typeof RepricingTypeEnum>;
export type EnergyClassificationType = z.infer<typeof EnergyClassificationType>;
export type GearboxType = z.infer<typeof GearboxType>;
export type ProductSources = z.infer<typeof ProductSources>;

export type VehiclePricing = z.infer<typeof VehiclePricing>;
export type VehicleBasicInfo = z.infer<typeof VehicleBasicInfo>;
export type VehicleExtras = Record<string, boolean>;
export type VehiclePerformance = z.infer<typeof VehiclePerformance>;
export type VehicleStatus = z.infer<typeof VehicleStatus>;
export type VehicleInfoView = z.infer<typeof VehicleInfoView>;

export type GetVehiclePriceResponse = z.infer<typeof GetVehiclePriceResponse>;
export type GetVehicleBasicInfoResponse = z.infer<typeof GetVehicleBasicInfoResponse>;
export type GetVehicleExtrasResponse = z.infer<typeof GetVehicleExtrasResponse>;
export type GetVehicleExtrasInfoResponse = z.infer<typeof GetVehicleExtrasInfoResponse>;
export type GetVehiclePerformanceResponse = z.infer<typeof GetVehiclePerformanceResponse>;
export type GetVehicleStatusResponse = z.infer<typeof GetVehicleStatusResponse>;

export type PostCatalogueVehicleForm = z.infer<typeof PostCatalogueVehicleForm>;
export type PostCatalogueVehicle = z.infer<typeof PostCatalogueVehicle>;

export type CatalogueVehicleMainStatuses = keyof typeof catalogueVehicleMainStatuses;
export type CatalogueVehicleSubStatuses = keyof typeof catalogueVehicleSubStatuses;
export type CatalogueVehiclesSubStatusesMap = typeof catalogueVehiclesSubStatusesMap;
export type CatalogueVehicleFilters = z.infer<typeof CatalogueVehicleFilters>;
export type CatalogueVehicleFormQueryFilters = z.infer<typeof CatalogueVehicleFormQueryFilters>;
export type CatalogueVehicleFormFilters = z.infer<typeof CatalogueVehicleFormFilters>;
export type CatalogueVehicleListDetails = z.infer<typeof CatalogueVehicleListDetails>;
export type PutCatalogueVehicleDetailsForm = z.infer<typeof PutCatalogueVehicleDetailsForm>;

export type GetCatalogueVehiclesListResponse = z.infer<typeof GetCatalogueVehiclesListResponse>;
export type SellerAd = z.infer<typeof SellerAd>;
export type GetCatalogueVehicleDetails = z.infer<typeof GetCatalogueVehicleDetails>;
export type PutCatalogueVehicleDetails = z.infer<typeof PutCatalogueVehicleDetails>;
export type GetNextCatalogueVehicleStatuses = z.infer<typeof GetNextCatalogueVehicleStatuses>;
export type ChangeCatalogueVehicleStatusProps = z.infer<typeof ChangeCatalogueVehicleStatusProps>;
export type CatalogueVehicleTransitionHistoryRecord = z.infer<typeof CatalogueVehicleTransitionHistoryRecord>;
export type CatalogueVehicleTransitionHistory = z.infer<typeof CatalogueVehicleTransitionHistory>;

export type PostCatalogueVehicleManagedAd = z.infer<typeof PostCatalogueVehicleManagedAd>;
export type CatalogueVehicleManagedAd = z.infer<typeof CatalogueVehicleManagedAd>;

export type PostCatalogueVehicleRepricing = z.infer<typeof PostCatalogueVehicleRepricing>;
export type GetCatalogueVehicleRepricing = z.infer<typeof GetCatalogueVehicleRepricing>;
export type CatalogueVehicleRepricing = z.infer<typeof CatalogueVehicleRepricing>;
export type NullablePrice = z.infer<typeof NullablePrice>;
export type CatalogueVehicleRepricingHistory = z.infer<typeof CatalogueVehicleRepricingHistory>;
export type CatalogueVehicleRepricingHistoryItem = z.infer<typeof CatalogueVehicleRepricingHistoryItem>;

export type BulkUpdateCsvSuccessesResponse = z.infer<typeof BulkUpdateCsvSuccessesResponse>;
export type BulkUpdateCsvUpdatesResponse = z.infer<typeof BulkUpdateCsvUpdatesResponse>;
